<template>
  <div class="modal fade" :class="{ show: show }" id="newRaffleModal" tabindex="-1" aria-labelledby="#newRaffleModal"
    :aria-hidden="!show" :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
    <div ref="newRaffleModalContainer" class="modal-dialog">
      <div class="modal-content font-main modal-border box-shadow">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Start New Raffle</h1>
          <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col col-md-12">
              <h6 class="text-warning small"><i class="bi bi-exclamation-octagon"></i>
                Contract & tokenid are found at Objkt (Properties Section)
              </h6>
            </div>
          </div>

          <form>

            <div class="">
              <label for="raffle-contract-id" class="col-form-label">Select Collection:</label>
              <div class="input-group">
                <span class="input-group-text"><i class="bi bi-card-list"></i></span>
                <select v-if="this.walletCollections.length === 0" class="form-select sm" id="raffle-contract-id"
                  aria-label="Default select example" v-model.trim="form.kt" @change="previewImage()">
                  <option v-for="(collection, index) in allCollections" :key="index" :value="`${collection.kt}`"
                    :selected="index[0]"
                    :class="{ 'd-none': collection.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && raffleStore.kt !== 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2' }">
                    {{ collection.name }}</option>
                </select>

                <select v-if="this.walletCollections.length > 0" class="form-select sm" id="raffle-contract-id"
                  aria-label="Default select example" v-model.trim="form.kt"
                  @change="(e) => { getCollectionTokens(e.target.value); previewImage() }">
                  <option v-for="(collection, index) in this.walletCollections" :key="index" :value="`${collection.kt}`"
                    :selected="index[0]"
                    :class="{ 'd-none': collection.kt === 'KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc' && raffleStore.kt !== 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2' }">
                    {{ collection.name }}</option>
                </select>
              </div>
            </div>

            <div class="" v-if="this.walletCollections.length > 0">
              <label for="raffle-contract-id" class="col-form-label">Select Token:</label>
              <div class="input-group">
                <span class="input-group-text"><i class="bi bi-card-list"></i></span>
                <select v-if="this.walletCollections.length > 0" class="form-select sm" id="raffle-contract-id"
                  aria-label="Select token from collection" v-model="form.tokenId" @change="previewImage()">
                  <option v-for="(token, index) in this.walletCollectionTokens" :key="index" :value="`${token.token_id}`"
                    :selected="index[0]">
                    #{{ token.token_id }} - {{ token.name }}</option>
                </select>
              </div>
            </div>

            <div class="">
              <label for="raffle-contract-id-custom" class="col-form-label">OR enter custom collection Contract:</label>
              <div class="input-group">
                <span class="input-group-text"><i class="bi bi-1-square"></i></span>
                <input type="text" class="form-control sm" id="raffle-contract-id-custom" v-model.trim="form.kt"
                  placeholder="KT..." @change="() => { this.walletCollectionTokens = []; previewImage() }">
              </div>
            </div>

            <div class="">
              <label for="raffle-token-id" class="col-form-label">Token ID:</label>
              <div class="input-group">
                <span class="input-group-text"><i class="bi bi-123"></i></span>
                <input type="text" class="form-control sm" id="raffle-token-id" v-model="form.tokenId"
                  placeholder="1234..." @change="previewImage()">
              </div>
            </div>
            <div class="">
              <label for="raffle-length" class="col-form-label">Length:</label>
              <div class="input-group">
                <span class="input-group-text"><i class="bi bi-clock-history"></i></span>
                <input type="number" min="1" max="10" step="1" class="form-control sm" id="raffle-length"
                  v-model="form.raffleLengthDays">
                <div class="input-group-append">
                  <span class="input-group-text" style="width:60px;">Days</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 col-md-4">
                <label for="raffle-tickets-max" class="col-form-label">Tickets:</label>
                <div class="input-group">
                  <span class="input-group-text"><i class="bi bi-ticket-detailed-fill"></i></span>
                  <input type="number" min="2" max="1000" class="form-control sm" id="raffle-tickets-max"
                    v-model="maxTickets">
                </div>
              </div>
              <div class="col col-12 col-md-8">
                <label for="raffle-price" class="col-form-label">Price:</label>
                <div class="input-group">
                  <span class="input-group-text d-md-none"> <i class="bi bi-cash-coin"></i> </span>
                  <input type="number" min="0" max="1000000" step="1" class="form-control sm" id="raffle-price"
                    v-model="form.ticketPrice">
                  <label for="raffle-token-contract-id" class="col-form-label sm">&nbsp;</label>
                  <span v-if="raffleStore.theme === 'pepe'">
                    <!-- <input type="hidden" id="raffle-contract-id" ref="theme-contract" :value="`${raffleStore.themeContract}`"> -->
                    <div class="input-group-append"><span class="input-group-text" style="width:60px;">{{
                      raffleStore.themeTicker }}</span></div>
                  </span>
                  <span v-else>
                    <select class="form-select sm" id="raffle-token-contract-id" aria-label="Default select example"
                      v-model.trim="form.priceToken">
                      <option v-for="(tokens, index) in raffleStore.tokens" :key="index" :value="`${tokens.kt}`">{{
                        tokens.name }}</option>
                    </select>
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-4 font-main-light-italic" style="text-align:right">
              <button class="btn btn-primary btn-sm" @click.prevent="optional = !optional" aria-expanded="false"
                aria-controls="collapseExample"><i class="bi bi-arrows-expand"></i> </button> &nbsp;Show Optional
            </div>
            <div :class="{ collapse: !optional }" id="collapseExample">
              <div class="mt-3">
                <div class="form-group">
                  <label for="raffle-tez" class="col-form-label">Editions (=Winners):</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="bi bi-trophy"></i></span>
                    <input type="number" min="1" max="100" step="1" class="form-control sm" id="raffle-tez"
                      v-model="form.editions">
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <label for="raffle-tickets-max-per-wallet" class="col-form-label sm">Max Tickets per Wallet (Default 25%
                    of Tickets):</label>
                  <div class="input-group">
                    <span class="input-group-text"><i class="bi bi-ticket-perforated-fill"></i></span>
                    <input v-model="maxEntries" type="number" min="1" max="100" step="1" class="form-control sm"
                      id="raffle-tickets-max-per-wallet">
                    <div class="input-group-append">
                    </div>
                  </div>
                </div>
              </div>
              <!--
              <div class="">
                <div class="form-group">
                      <label for="raffle-tez" class="col-form-label sm">Add Tez to Prize Pool:</label>
                    <div class="input-group">                 
                      <span class="input-group-text">ꜩ &nbsp;</span>
                      <input v-model="form.addTezToPrizePool" type="number" min="0" max="2000" step="1" class="form-control sm" id="raffle-tez">
                    </div>
                </div>
              </div>
              
              <div class="">
              <div class="form-group">
                    <label for="raffle-currency" class="col-form-label">Set custom raffle currency contract:</label>
                  <div class="input-group">                 
                    <span class="input-group-text">$ &nbsp;</span>
                    <input type="text"  step="1" class="form-control sm" id="raffle-currency">
                  </div>
                </div>              
              </div>
              -->
            </div>
            <div class="form-check form-switch mt-3 font-main-light small">
              <input v-model="form.featured" class="form-check-input sm" type="checkbox" id="raffle-featured">
              <label class="form-check-label small" for="raffle-featured"><i class="bi bi-patch-check"></i> Featured
                Raffle (0.15ꜩ)</label>
            </div>
          </form>
          <div class="row">
            <div class="col col-md-7">
              <div class="mt-4 muted small font-main-light-italic" style="font-size:13px">
                I understand that I am using this product at my own responsibility (No Audits) and read the <a
                  href="#faqModal" target="_blank" class="text-decoration-none">Terms & Services</a>
              </div>
            </div>
            <div class="col col-md-5">
              <h6 class="font-main-light small"><i class="bi bi-image"></i>NFT-Preview: <i v-if="this.flagged"
                  class="bi bi-flag-fill text-danger" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Flagged Asset" /></h6>
              <div class="">
                <img :src="imageUrl" class="w-100" style="" placeholder="nft-image">
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button v-if="raffleStore.pkh === 'tz1YQKSkAt9VonCMwq6MbHdqMmPA2Jd3GAh2' ||'tz1cryJH3WJsRzm87gT8pnuTuzfhfQjWk99o'|| 'tz1eUfFXTHDHJgaXX1GVuEpUpJuiPWq7eDZE'" @click="addToBox" type="button" class="btn btn-warning" :disabled="this.flagged">
            Add to Box
            </button>
            <button v-if="raffleStore.pkh === 'tz2FycgMoHM5rATJ75DbA27XPLvKgrPQXH9X' || 'tz1eUfFXTHDHJgaXX1GVuEpUpJuiPWq7eDZE'" @click="addToCollabBox" type="button" class="btn btn-warning" :disabled="this.flagged">
            Add to CollabBox
            </button>
          <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button @click="onSubmit" type="button" class="btn btn-primary" :disabled="this.flagged">
            <span v-if="working">
              <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
            </span>
            <span v-else>Create Raffle</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SelfBuildingSquareSpinner } from 'epic-spinners'
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: 'ModalsNewRaffle',
  props: {
    show: Boolean
  },
  emits: ['close-modal', 'close'],
  components: {
    SelfBuildingSquareSpinner,
  },
  data() {
    return {
      optional: false,
      working: false,
      collections: [],
      x_maxPercent: 25,
      tokens: [],
      imageUrl: "https://placehold.co/150x100?text=NFT-Preview",
      form: {
        kt: '',
        tokenId: '',
        ticketPrice: 1,
        maxTickets: 100,
        raffleLengthDays: 3,
        editions: 1,
        maxEntries: 1,
        featured: true,
        addTezToPrizePool: 0,
        tokenFactor: 0,
        priceToken: '',
      },
      walletCollections: [],
      walletCollectionTokens: [],
      flagged: false
    }
  },
  computed: {
    ...mapStores(useRaffleStore),

    maxTickets: {
      get() {
        return this.form.maxTickets || 1
      },
      set(value) {
        this.form.maxTickets = value || 1
        this.form.maxEntries = Math.ceil(value / 100 * this.x_maxPercent)
      }
    },
    maxEntries: {
      get() {
        return this.form.maxEntries || 1
      },
      set(value) {
        this.form.maxEntries = value || 1
        this.x_maxPercent = this.form.maxEntries / this.form.maxTickets * 100
      }
    },
    // fallback for when wallet address is not available
    allCollections() {
      return this.raffleStore.raffles.map((c) => { return { kt: c.kt, name: c.prize?.collection } })
        .filter((value, index, self) =>
          index === self.findIndex((c) => (
            c.name === value.name
          ))
        ).sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.working = true
        this.raffleStore.createRaffle(this.form)
        await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
      } catch (e) {
        console.log(e)
      } finally {
        this.working = false
      }
    },
    async addToBox() {
      try {
        this.working = true
        console.log(this.form.kt, this.form.tokenId)
        this.raffleStore.addToBox({kt: this.form.kt, tokenId: this.form.tokenId})
        await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
      } catch (e) {
        console.log(e)
      } finally {
        this.working = false
      }
    },
    async addToCollabBox() {
      try {
        this.working = true
        console.log(this.form.kt, this.form.tokenId)
        this.raffleStore.addToCollabBox({kt: this.form.kt, tokenId: this.form.tokenId})
        await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
      } catch (e) {
        console.log(e)
      } finally {
        this.working = false
      }
    },
    async previewImage() {
      if (!this.form.tokenId) return;
      this.flagged = false
      const flagInfo = await this.raffleStore.getTokenFlag({ kt: this.form.kt, tokenId: this.form.tokenId });
      // console.log(flagInfo)
      if (flagInfo === "banned") this.flagged = true
      // getTokenMetaData for IMG 
      this.imageUrl = "https://placehold.co/150x100?text=NFT-Preview"
      if (this.form.tokenId === '' || this.form.kt === '') return
      const meta = await this.raffleStore.getTokenMetaData({ kt: this.form.kt, tokenId: this.form.tokenId });
      console.log(meta);
      let shortlink = ''
      if (typeof meta[0]?.metadata?.displayUri !== "undefined") { shortlink = meta[0]?.metadata?.displayUri.substring(7) } else { shortlink = "" }
      let url = "https://nftstorage.link/ipfs/" + shortlink
      console.log(url);
      this.imageUrl = url

    },
    // prevent clicking inside the card from triggering the closeModal
    stopPropagation(e) {
      e.stopPropagation()
    },
    closeModal() {
      this.$emit('close-modal')
    },
    async getCollectionTokens(kt) {
      // grab collection tokens in wallet
      if (this.raffleStore.pkh && kt) {
        this.walletCollectionTokens = await this.raffleStore.getCollectionTokens(kt)
        // console.log(this.walletCollectionTokens)
        console.log(`${this.walletCollectionTokens.length} different tokens from collection in wallet`)
      }
    }
  },
  async mounted() {
    this.form.maxEntries = Math.ceil(this.maxTickets * 0.25) || 1
    // attach listeners for handling clicks outside the card, while preventing propagation
    // of clicks in the cards
    this.$refs.newRaffleModalContainer.addEventListener('click', this.stopPropagation)
    document.body.addEventListener('click', this.closeModal)

    // grab wallet collections
    if (this.raffleStore.pkh) {
      this.walletCollections = await this.raffleStore.getWalletCollections(this.raffleStore.pkh)
      console.log(`${this.walletCollections.length} collections in wallet`)
    }
  },
  beforeUnmount() {
    // cleanup event listeners
    this.$refs.newRaffleModalContainer.removeEventListener('click', this.stopPropagation)
    document.body.removeEventListener('click', this.closeModal)
  },
}
</script>