<template>
    <div class="ModalElement">
        <ModalsFaq @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'faqModal'"
            :show="modal === 'faqModal'" />
        <ModalsNewRaffle @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'newRaffleModal'"
            :show="modal === 'newRaffleModal'" />
        <ModalsRaffleDetail @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'raffle'"
            :show="modal === 'raffle'" :raffle="raffleId" :key="raffleStore.pkh" />
        <TzRaffleModal @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'tzraffle'"
            :show="modal === 'tzraffle'" :key="raffleStore.pkh" />
        <StatsModal @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'statsModal'"
            :show="modal === 'statsModal'" :key="raffleStore.pkh" />
        <NftBoxModal @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'nftBoxModal'"
            :show="modal === 'nftBoxModal'" /> 
        <NftBoxCollabModal @close-modal="onModalClose" @close="onModalClose" v-if="modal === 'collabBoxDrops'"
        :show="modal === 'collabBoxDrops'" /> 
    </div>
</template>

<script>
import ModalsRaffleDetail from './ModalsRaffleDetail.vue';
import ModalsNewRaffle from './ModalsNewRaffle.vue';
import ModalsFaq from './ModalsFaq.vue';
import TzRaffleModal from './tzRaffleModal.vue';
import StatsModal from './statsModal.vue';
import NftBoxModal from './ModalsNFTBox.vue';
import NftBoxCollabModal from './ModalsNFTBoxCollab.vue';
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'
// import { validateAddress } from '@taquito/utils';


export default {
    name: "ModalsContainer",
    components: {
        ModalsRaffleDetail,
        ModalsNewRaffle,
        ModalsFaq,
        TzRaffleModal,
        StatsModal,
        NftBoxModal,
        NftBoxCollabModal,
    },
    computed: {
        ...mapStores(useRaffleStore)
    },
    data() {
        return {
            modal: '',
            raffleId: '',
            userId: '',
            theme: '',
        }
    },
    mounted() {
        this.onURL(window.location.href)
        this.onURL2(window.location.href)
        window.addEventListener('hashchange', (e) => {
            this.onURL(e.newURL)
        })
    },
    methods: {
        onURL(url) {
            if (url.includes("#")) {
                const [, hash] = url.split('#')
                const [modal, id] = hash.split('/')
                this.raffleId = Number(id) || 0
                this.modal = modal || ''
            }
        },
        onURL2(url) {
            if (url.includes("?")) {
                const [, split] = url.split('?')
                const [wallet,] = split.split('#')
                if (wallet.length == 36 && wallet.slice(0,2) === 'tz' && wallet.match("[a-zA-Z0-9]*")) this.raffleStore.user_filter = wallet
            }
        },
        onModalClose() {
            if (history.pushState) {
                this.modal = ''
                history.pushState(null, null, '#');
            }
            else {
                location.hash = '#';
            }
        }
    },
}
</script>