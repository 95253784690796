<template>
  <div id="wrapper" class="d-flex">
    <div id="page-content-wrapper">
      <Header />
      <MainElement />
      <Modals />
      <FeaturedRafflesElement />
      <RafflesElement />
      <FooterElement />
    </div>
    <div id="sidebar-wrapper">
      <SidebarElement />
    </div>
  </div>
</template>

<script>
import { useRaffleStore } from '@/stores/RaffleStore'
import Header from "./components/Header.vue";
import Modals from '@/components/Modals.vue'
import MainElement from '@/components/MainElement.vue'
import FeaturedRafflesElement from '@/components/FeaturedRafflesElement.vue'
import RafflesElement from '@/components/RafflesElement.vue'
import FooterElement from '@/components/FooterElement.vue'
import SidebarElement from '@/components/SidebarElement.vue'

export default {
  name: "App",
  components: {
    Header,
    MainElement,
    FeaturedRafflesElement,
    RafflesElement,
    Modals,
    FooterElement,
    SidebarElement,
  },
  setup() {
    const store = useRaffleStore()
    store.checkWalletConnection();
    store.setRaffles()
    store.setWallets()
    store.setCollections()
    store.setTokens()
    store.setTheme()
    store.init()
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style>
#wrapper {
  overflow-x: hidden;
  background-image: url('../public/Bootswatch_ Cyborg_files/images/mainbg.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.d-flex {
  display: flex !important;
}

#page-content-wrapper {
  min-width: 100vw;
}

@media (min-width: 1800px) {
  #page-content-wrapper {
    min-width: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
  }
}

@media (min-width: 1800px) {
  .navbar {
    min-width: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
  }
}

#sidebar-wrapper {
  width: 250px;
  min-height: 100vh;
  margin-left: 0rem;
  background: transparent/* #10191e; */
}
</style>
