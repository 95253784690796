<template>
  
  <div class="container">

    <div v-if="raffleStore.user_filter" class="row" style="justify-content: center;">
      <div class="col-md-auto mb-2">
        <a href="/"><button type="button" class="btn btn-light btn-sm mb-2 box-shadow">Reset Wallet Filter = Show all</button></a>
      </div>
    </div>

    <div class="row" style="justify-content: center;">
      <div class="col-md-auto mb-2">
        <button type="button" class="btn btn-light btn-sm mb-2" value="" @click.prevent="() => this.raffleStatus = ''"
          :class="{ active: !raffleStatus }">New</button>
        <button type="button" class="btn btn-light btn-sm mb-2" :class="{ active: raffleStatus === status.value }"
          v-for="status in availableStatus" :key="status.title" :value="`${status.value}`"
          @click.prevent="() => this.raffleStatus = status.value">{{
            status.title }}</button>
      </div>
    </div>

    <div class="row" style="justify-content: center;">
      <div class="col-md-auto mb-2" v-if="raffleStore.theme !== 'pepe'">
        <label for="raffle-token-kt" class="col-form-label">Token:</label>
        <div class="input-group" style="width:250px;">
          <span class="input-group-text"><i class="bi bi-coin"></i></span>
          <select class="form-select sm" id="raffle-token-kt" aria-label="Select Currency" v-model="tokenKt">
            <option value="" selected="selected">All Tokens</option>
            <option v-for="(token, index) in raffleStore.tokens" :key="index" :value="`${token.kt ? token.kt : 'tez'}`">{{
              token.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-auto mb-2">
        <label for="raffle-contract-id" class="col-form-label">Collection:</label>
        <div class="input-group" style="width:250px;">
          <span class="input-group-text"><i class="bi bi-images"></i></span>
          <select class="form-select sm" id="raffle-contract-id" aria-label="Select Collection" v-model="kt">
            <option value="" selected="selected">All Collections</option>
            <!-- <option v-for="(collection, index) in raffleStore.collections" :key="index" :value="`${collection.kt}`">{{
              collection.name }}</option> -->
            <option v-for="(collection, index) in allCollections" :key="index"
              :value="`${collection.kt ? collection.kt : 'tez'}`">{{ collection.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- <div class="col mb-2">
        <label for="raffle-status" class="col-form-label">Status:</label>
        <div class="input-group" style="width:300px;">
          <span class="input-group-text"><i class="bi bi-images"></i></span>
          <select class="form-select sm" id="raffle-status" aria-label="Select Status" v-model="raffleStatus">
            <option value="" selected="selected">All Status</option>
            <option v-for="status in availableStatus" :key="status.title" :value="`${status.value}`">{{
              status.title }}</option>
          </select>
        </div>
      </div> -->
    </div>

    <div class="row" v-if="dataReady" style="justify-content: center;">
      <RaffleCard v-for="raffle in allRaffles " :raffle="raffle" :key="Number(raffle.id)"
        :fav="myFav.includes(parseInt(raffle.id))" class="card card-hover box-shadow main-card mb-3" />
    </div>

  </div>
</template>

<script>
import { toRaw } from "vue";
import RaffleCard from "../RaffleCard"
import { mapStores } from 'pinia'
import { useRaffleStore } from '@/stores/RaffleStore'

export default {
  name: 'AllRafflesElement',
  props: {
    msg: String
  },
  components: {
    RaffleCard,
  },
  data() {
    return {
      kt: '',
      tokenKt: '',
      raffleStatus: '',
      plugins: [],
      dataReady: false,
      collections: []
    }
  },
  computed: {
    ...mapStores(useRaffleStore),
    allRaffles() {
      if (!this.kt && !this.tokenKt && !this.raffleStatus) return this.raffleStore.raffles.filter(rf => !rf.resolved_ts || rf.resolved_ts === "").slice(0).reverse()

      return this.raffleStore.rafflesFiltered(this.kt, this.tokenKt, this.raffleStatus)
    },
    allCollections() {
      return this.raffleStore.raffles.map((c) => { return { kt: c.kt, name: c.prize?.collection } })
        .filter((value, index, self) =>
          index === self.findIndex((c) => (
            c.name === value.name
          ))
        ).sort((a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });

    },
    availableStatus() {
      let availableStatus = [
        { title: 'Ending', value: "ending_raffle" },
        { title: 'Bonus Prize', value: "added_prize" },
        { title: 'Free', value: "free_raffle" },
        { title: 'Ended', value: "ended_raffle" },
        { title: 'Sold Out', value: "sold_out" }
        // { title: 'Active', value: "active_raffle" },
      ];

      if (this.raffleStore.pkh) {
        this.raffleStore.getMyRaffleTickets()
        availableStatus.push({ title: 'Entered', value: "entered_raffle" })
        availableStatus.push({ title: 'Created', value: "created_raffle" })
        availableStatus.push({ title: 'Won', value: "won_raffle" })
      }
      if (this.raffleStore.my_favorites.length) {
        availableStatus.push({ title: 'Favorites', value: "favorite_raffle" })
      }

      return availableStatus
    },
    myFav() {
      // console.log(this.raffleStore.myFavorites)
      return toRaw(this.raffleStore.myFavorites) || []
    }
  },
  async mounted() {
    setInterval(async function () {
      await this.raffleStore.setRaffles()
      await this.raffleStore.getMyRaffleTickets()
      // console.log('ended: set raffles and tickets')
    }.bind(this), 15000);
    this.dataReady = true;
  },
  methods: {
  },
}
</script>

<style scoped>
.btn {
  margin-right: 10px;
}

.btn.active,
.btn.show {
  color: var(--bs-btn-active-bg);
  background-color: var(--bs-btn-active-color);
  border-color: var(--bs-btn-active-border-color);
}
</style>
 