<template>
  <!--RaffleDetailModal-->
  <div class="modal fade" :class="{ show: show }" id="raffle" aria-labelledby="#raffle" tabindex="-1" :aria-hidden="!show"
    :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
    <div ref="tzRaffleDetailModalContainer" class="modal-dialog">
      <div class="modal-content modal-border box-shadow">
        <div class="modal-header pb-075">
          <div class="card-body text-center text-truncate">
            <h5 class="card-title font-main-light text-truncate fs-1-25">
              tzRaffle #7 Batch Buy Tool
            </h5>
            <h6 class="text-muted small mt-2">
              tzRaffles are our rare but bigger raffles. <br>Each buy is a NFT-Ticket (<a
                href="https://objkt.com/asset/KT1JAZLFewgBxS2W3tPH8brNTHStwXZrpfXc/8" target="_blank"><i
                  class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png"
                  style="height:13px;padding-left:5px"></a>)
            </h6>
          </div>

          <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body pb-0">
          <div class="card-body text-center">
            <div class="row">
              <div class="col-sm-7">
                <div class="img-wrapper img-wrapper-grid" style="">
                  <img src="Bootswatch_ Cyborg_files/images/tzRaffle7.gif" class="d-block w-100">
                </div>
              </div>
              <div class="col-sm-5 text-start pe-1 pt-3">
                <h5 class="font-main"><small><i class="bi bi-ticket-detailed-fill pe-1"></i> {{ 2000 - this.balance
                }}/2000 sold</small></h5>
                <span class="small">
                  <span class=" font-main-light">Prize LVLs:</span>
                  <br>
                  🔓 LVL 1 unlocked <br>
                  {{ 2000 - this.balance >= 500 ? "🔓" : "🔒" }} LVL 2 {{ 2000 - this.balance >= 500 ? "unlocked" :
                    "unlocks at 500" }}<br>
                  {{ 2000 - this.balance >= 1000 ? "🔓" : "🔒" }} LVL 3 {{ 2000 - this.balance >= 1000 ? "unlocked" :
                    "unlocks at 1000" }}<br>
                  {{ 2000 - this.balance >= 1500 ? "🔓" : "🔒" }} LVL 4 {{ 2000 - this.balance >= 1500 ? "unlocked" :
                    "unlocks at 1500" }}<br>
                </span><br><br>
                <span class="font-main-light small">Manual Prize drawing livestream when sold-out! Just hold the <i
                    class="bi bi-ticket-detailed-fill pe-1"></i> in your wallet. More information in our <a
                    href="https://discord.gg/JPC2WND2PR"><i class="bi bi-discord fs-12 " style="color:#7289DA;"></i>
                    Discord</a>
                </span>
              </div>
            </div>

            <ul class="list-group list-group-flush text-center">
              <li class="list-group-item"></li>
              <li class="list-group-item pt-4 pb-4">
                <div class="row">
                  <div class="col-md-3 pb-2">
                    <div class="small font-main-light-italic">Tickets</div>
                    <input type="number" class="form-control font-main text-center" id="buy-quantity"
                      v-model="buyTicketsAmount" min="1" style="height:45px;">
                  </div>
                  <div class="col-md-9 font-main-light-italic">
                    <div class="font-main-light-italic small mb-1">
                      <vue-countdown v-if="1701633600000 > new Date().getTime()"
                        :time="1701633600000 - new Date().getTime()" :interval="100"
                        v-slot="{ days, hours, minutes, seconds }" style="text-decoration: none!Important;">
                        Sale Starts in
                        <i class="bi bi-clock-history pe-1"></i>
                        <span v-show="days > 0">{{ days }}D:</span>
                        <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds
                        }}S</span>
                      </vue-countdown>
                      <vue-countdown v-else :time="1704312000 - new Date().getTime()" :interval="100"
                        v-slot="{ days, hours, minutes, seconds }" style="text-decoration: none!Important;">
                        SALE LIVE
                        <i class="bi bi-clock-history pe-1"></i>
                        <span v-show="days > 0">{{ days }}D:</span>
                        <span v-show="hours > 0 || minutes > 0 || seconds > 0">{{ hours }}H:{{ minutes }}M:{{ seconds
                        }}S</span>
                      </vue-countdown>
                    </div>
                    <button @click="buyTickets" type="button" class="btn btn-primary w-100 text-center"
                      :class="{ disabled: (1701633600000 - new Date().getTime() > 0) }" style="height:45px;">
                      <span v-if="working" class="text-center">
                        <self-building-square-spinner style="margin: 0 auto;" :animation-duration="6000" :size="25"
                          :color="'#23c2cc'" />
                      </span>
                      <span v-else>Buy <span id="button-quantity">{{ buyTicketsAmount }}</span> <i
                          class="bi bi-ticket-detailed-fill"></i> for <span v-if="raffleStore.theme === 'pepe'"
                          id="button-volume">{{ (buyTicketsAmount * 2000) }} $pepe</span>
                        <span v-if="raffleStore.theme !== 'pepe'" id="button-volume">{{ (buyTicketsAmount * 1) }}
                          TEZ</span>
                      </span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { SelfBuildingSquareSpinner } from 'epic-spinners'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { mapStores } from 'pinia';
import { useRaffleStore } from '@/stores/RaffleStore';

export default {
  name: 'ModalsRaffleDetail',
  props: {
    show: Boolean,
    raffle: Number,
  },
  emits: ['close-modal', 'close'],
  components: {
    VueCountdown: VueCountdown,
    SelfBuildingSquareSpinner,
  },
  computed: {
    ...mapStores(useRaffleStore),
  },
  methods: {
    switchTab(tab) {
      this.tab = tab
    },
    // Raffle Interactions
    async buyTickets() {
      try {
        // first objkt tez listing id : 3746308
        // first pepe et nunc $pepe listing : 3746306
        this.working = true

        if (this.raffleStore.theme === "pepe") {
          await this.raffleStore.batchBuyPepeTickets({ quantity: this.buyTicketsAmount, raffleId: 4249440, ticketPrice: 200000, priceToken: "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9" })
        } else {
          await this.raffleStore.batchBuyTickets({ quantity: this.buyTicketsAmount, raffleId: 4245824, ticketPrice: 1 })
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.working = false
      }
    },
    stopPropagation(e) {
      e.stopPropagation()
    },
    closeModal() {
      this.$emit('close-modal')
    }
  },
  data() {
    return {
      optional: false,
      working: false,
      tab: 'tzraffle',
      buyTicketsAmount: 1,
      balance: 2000
    }
  },
  beforeUnmount() {
    // cleanup event listeners
    this.$refs.tzRaffleDetailModalContainer.removeEventListener('click', this.stopPropagation)
    document.body.removeEventListener('click', this.closeModal)
  },
  async mounted() {
    // tzRaffle #7 tokenId 8, 2000 supply
    this.balance = await this.raffleStore.getRaffleBalance(8) || 2000
    // attach listeners for handling clicks outside the card, while preventing propagation
    // of clicks in the cards
    this.$refs.tzRaffleDetailModalContainer.addEventListener('click', this.stopPropagation)
    document.body.addEventListener('click', this.closeModal)
  },
}
</script>
  
<style scoped>
.np-ht {
  height: 600px;
}

.np-header {
  padding: 30px;
  background: #eee;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.v-lazy-image {
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.favorite-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon-green {
  color: lightgreen;
}
</style>