// export const CONTRACT_ADDRESS_CUSTODIAL = "KT1EBnGPDG7ZT6UNvPLQLfmKd4YsgNX7jvff";
export const CONTRACT_ADDRESS_RAFFLES = process.env.VUE_APP_CONTRACT;
export const CONTRACT_ADDRESS_OBJKT = "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC";
export const CONTRACT_ADDRESS_BOX = "KT1AnoXF2FBQaXN6D2T3bKPgR99VFZWuQepY";
export const CONTRACT_ADDRESS_BOX_COLLAB =
  "KT1UTE3NwWN6CGMVn4PYBD4R363rFa1Pqbsi";

export const TOKENS = [
  { name: "TEZ", symbol: "TEZ", kt: "", decimals: 6, factor: 1000000 },
  {
    name: "$PEPE",
    symbol: "$PEPE",
    kt: "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9",
    decimals: 2,
    factor: 100,
  },
  {
    name: "$SKULL",
    symbol: "$SKULL",
    kt: "KT1BEHqhkuqpBoMKuMZSQNx1fEwWp4dranEH",
    decimals: 3,
    factor: 1000,
  },
  {
    name: "k $ELXR",
    symbol: "$ELXR",
    kt: "KT1BsZVD9rQ67RERns4TEhQ5aav5s6QGa2gL",
    decimals: 9,
    factor: 1000000000000,
  },
  {
    name: "$TCOIN",
    symbol: "$TCOIN",
    kt: "KT1GorwGk5WXLUc3sEWrmPLQBSekmYrtz1sn",
    decimals: 8,
    factor: 100000000,
  },
  {
    name: "$UNO",
    symbol: "$UNO",
    kt: "KT1ErKVqEhG9jxXgUG2KGLW3bNM7zXHX8SDF",
    decimals: 9,
    factor: 1000000000,
  },
  {
    name: "$USDT",
    symbol: "$USDT",
    kt: "KT1XnTn74bUtxHfDtBmm2bGZAQfhPbvKWR8o",
    decimals: 6,
    factor: 1000000,
  },
  {
    name: "M $UP",
    symbol: "M $UP",
    kt: "KT1TgmD7kXQzofpuc9VbTRMdZCS2e6JDuTtc",
    decimals: 0,
    factor: 1000000,
  },
  {
    name: "$FRZ",
    symbol: "$FRZ",
    kt: "KT1DCiLVoASFp2R4t5Vpg3FVDH6QTdENd6Tw",
    decimals: 0,
    factor: 1,
  },
  {
    name: "$TRASH",
    symbol: "$TRASH",
    kt: "KT1ShjB24tTXX8m5oDCrBHXqynCpmNJDxpD5",
    decimals: 8,
    factor: 100000000,
  },
  {
    name: "$POLE",
    symbol: "$POLE",
    kt: "KT1UQVEDf4twF2eMbrCKQAxN7YYunTAiCTTm",
    decimals: 8,
    factor: 100000000,
  },
];
// export const TOKENS = [ { name: 'TEZ', symbol: 'TEZ', kt: '', decimals: 6, factor: 1000000 },  {name: '$RC', symbol: '$RC', kt: 'KT1TJ53kra5YhkmR6oPS4c7YvtkoQGZShNpe', decimals: 0, factor: 1 }, {name: '$Fa1.2test', symbol: '$FA12Test', kt: 'KT1Lbhakn2jXUdAQ63GPgWkLP7jRLAUCi3my', decimals: 0, factor: 1 } ]

export function formatPkhString(pkh = "") {
  return pkh.substring(0, 4) + "..." + pkh.substring(pkh.length - 3);
}

import { useRaffleStore } from "@/stores/RaffleStore";

// return tez.domain or shorten wallets
export function formatAddress(pkh = "") {
  const store = useRaffleStore();
  if (pkh === "") return 0;
  var newWallets = JSON.parse(JSON.stringify(store.wallets));
  var filtered = newWallets.filter((element) => element.wallet === pkh);
  if (filtered.length == 1) return filtered[0].domain;
  return pkh.substring(0, 4) + "..." + pkh.substring(pkh.length - 3);
}
