<template>
    <div class="modal fade" :class="{ show: show }" id="collabBoxDrops" tabindex="-1" aria-labelledby="#collabBoxDrops"
      :aria-hidden="!show" :aria-modal="show" :style="{ display: show ? 'block' : 'none' }">
      <div ref="nftBoxModalContainer" class="modal-dialog">
        <div class="modal-content font-main modal-border box-shadow">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Collab-Box Drops</h1>
            <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <a href="https://twitter.com/tezakai91596/" target="_blank">
          <img src="https://media.discordapp.net/attachments/1173030346745446402/1178373711615758356/skull-degen-club-800x195.png?ex=6575e909&is=65637409&hm=0a452b5b73432fd59116cce4dd12a7ecd41f9c9a8bb0fa86f943c56af1309eb1&=&format=webp" style="width:100%;background-size:contain ;background-repeat:no-repeat;">
          </a>

          <div class="modal-body"> <!-- Bootswatch_ Cyborg_files/images/box-banner3.png -->

            <div class="row">

                <div class="col col-sm-12 text-center mt-1">

                    <h6 class="text-warning small"><i class="bi bi-exclamation-circle"> Current Drop is <a class="text-warning" href="https://objkt.com/collection/KT1MvvVCFajWRxtuDuGoyiXBPfkF8AKL8fuG" target="_blank">SOLD OUT</a></i></h6>

                    <h6>{{ raffleStore.boxcount_collab }} NFTS in Box <a class="color-none" href="https://objkt.com/profile/KT1UTE3NwWN6CGMVn4PYBD4R363rFa1Pqbsi/owned?sort=highest_offer:desc" target="_blank"> <i class="bi bi-zoom-in ms-1"></i></a></h6>

                    <img src="https://start-page.buffer.com/cdn-cgi/image/width=1280/https://buffer-start-page-uploads.s3.amazonaws.com/654807ce15ca92f9acc7605f/1700763675880.anim-SDC-100-24.gif" class="" style="max-width:280px;width:70%;">

                </div>

            </div>

            <div class="row">

                <div class="col col-sm-12 mt-2 mb-2 text-center">
                    
                    <button @click="openBoxCollabTez" class="btn btn-primary tilt-shaking ms-1 box-shadow mb-2" :disabled="this.paused" style="top:0px;font-size:22px;margin-left:50px;border:1px solid #fff">
                    <span v-if="working">
                        <self-building-square-spinner :animation-duration="6000" :size="25" :color="'#23c2cc'" />
                    </span>
                    <span v-else><img src="Bootswatch_ Cyborg_files/images/key.png" class="tilt-shaking" style="width:45px;"> 2 Tez</span>
                    </button>
                    <br>Open to receive a random 1/1 $SKULL PFP of the box
                    
                    <h6 class="text-warning small" style="padding-left:5%;padding-right:5%;">
                    <i class="bi bi-exclamation-triangle"></i> Read the <a class="text-warning" href="https://www.tzraffles.fun/#faqModal" target="_blank">F.A.Q.</a> first
                    </h6>
                </div>

                <div class="row">         
                </div>    

                <div class="mt-3 text-center">
                <ul class="nav nav-tabs font-main-italic" role="tablist" style="display:inline-flex;">
                  <li class="nav-item" role="presentation">
                    <button @click="switchTab('test')" :class="{ active: tab === 'test' }" class="nav-link"
                      aria-selected="true" role="tab">History</button>
                  </li>
                </ul>
                <div id="myTabContentModal" class="tab-content text-center"
                  style="font-size:0.9em;">

                  <div class="tab-pane fade show active" v-if="tab === 'test'" role="tabpanel">
                    <div class="tabtab">
                      <div class="pt-2">
                        <div v-if="raffleStore.boxes_collab.length > 0">
                            <div v-for="e in raffleStore.boxes_collab.slice(0,20)" :key="e.id" class="font-main-light mb-2 mt-2">    
                                <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1" style="width:20px;">
                                <!-- <img :src="e.payload.nat" class="ms-1 me-1 mb-1" style="width:100px;">     -->
                                <span class="small">{{ timeDifference(e.timestamp) }}: </span><span class="me-1" v-if="e.payload.address_0 === raffleStore.pkh">🎉</span><span class="me-1 font-main" :class="{ 'text-success': e.payload.address_0 === raffleStore.pkh}" style="">{{ formatAddress(e.payload.address_0) }}</span>
                                <a :href="`${objktLink}` + `${e.payload.address_1}` + '/' + `${e.payload.nat}`" target="_blank"><i
                                    class="bi bi-box-arrow-in-up-right"></i><img src="Bootswatch_ Cyborg_files/images/objkt-white.png"
                                    style="height:15px;padding-left:5px"></a><br>
                                    <span class="small">({{ e.payload.address_1 }}- {{ e.payload.nat }})</span>
                            </div>
                        </div>
                        <div v-else>
                            <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                            <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                            <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                            <img src="Bootswatch_ Cyborg_files/images/treasure.png" class="ms-1 me-1 mb-1" style="width:100px;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { SelfBuildingSquareSpinner } from 'epic-spinners'
  import { mapStores } from 'pinia'
  import { useRaffleStore } from '@/stores/RaffleStore'
  import { formatPkhString } from "../utils";

  export default {
    name: 'ModalsNFTBox',
    props: {
      show: Boolean
    },
    emits: ['close-modal', 'close'],
    components: {
      SelfBuildingSquareSpinner,
    },
    data() {
      return {
        optional: false,
        working: false,
        tab: 'test',
        objktLink: "https://objkt.com/asset/",
        imageUrl: "https://placehold.co/150x100?text=NFT-Preview",
        form: {
          kt: '',
          tokenId: '',
        },
        paused: false
      }
    },
    computed: {
      ...mapStores(useRaffleStore),
    }, 
    methods: {
        switchTab(tab) {
            this.tab = tab
            },
      async openBoxCollabTez() {
        try {
          this.working = true
          this.raffleStore.openBoxCollabTez({ amount: 2000000 }) // 2000000
          await new Promise(resolve => setTimeout(resolve, 6000)); // 6 sec
        } catch (e) {
          console.log(e)
        } finally {
          this.working = false
        }
      },
      formatAddress(pkh) {
      if (pkh === '') return 0
      var newWallets = JSON.parse(JSON.stringify(this.raffleStore.wallets))
      var filtered = newWallets.filter(element => element.wallet === pkh)
      if (filtered.length == 1) return filtered[0].domain
      return formatPkhString(pkh);
    },
      // prevent clicking inside the card from triggering the closeModal
      stopPropagation(e) {
        e.stopPropagation()
      },
      closeModal() {
        this.$emit('close-modal')
      },
      timeDifference(previous) {

        var current = new Date();
        previous = new Date(previous)

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + 's ago';
        }

        else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + 'm ago';
        }

        else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + 'h ago';
        }

        else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + 'D ago';
        }

        else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + 'M ago';
        }

        else {
        return Math.round(elapsed / msPerYear) + 'Y ago';
        }
        }
    },
    setup() {

  },
    async mounted() {
        const store = useRaffleStore()
        store.setBoxCollab()
        console.log(store.boxes_collab)

        setInterval(async function () {
        // console.log('refreshing events', Date.now())
        store.setBoxCollab()
        }, 60000);
    },
    
    beforeUnmount() {

    },
  }
  </script>