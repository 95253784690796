import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueConfetti from "vue-confetti";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import VueSocialSharing from "vue-social-sharing";

createApp(App)
  .use(createPinia())
  .use(Vue3Toasity)
  .use(VueConfetti)
  .use(VueSocialSharing)
  .use(FloatingVue, {
    disposeTimeout: 0,
    themes: {
      tooltip: {
        placement: "top",
        triggers: ["hover", "focus", "touch"],
      },
    },
  })
  .mount("#app");
